<template>
  <router-view />
</template>

<style lang="scss">
@import "./animations/animations.scss";
* {
  @apply font-sans;
}
</style>
